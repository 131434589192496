<template>
    <b-modal id="modal-ordenar-cuso" hide-footer size="xl">
        <b-row class="category">
            <b-col cols="12" md="10" class="mx-auto pt-1">
                <div class="flexHeader">
                    <div>
                        <div class="title">Ordenação de Cursos</div>
                        <div class="subtitle">Selecione uma categoria e ordene seus Cursos.</div>
                    </div>
                    <div>
                        <b-form-select @change="NewCategory" v-model="selectedCategory">
                            <b-form-select-option v-for="item in AllHome" :key="item.id" :value="item">{{ item.title }}</b-form-select-option>
                        </b-form-select>
                    </div>
                </div>
                <div class="lineDiv"></div>
                <div class="animationOpacity2">
                    <div class="spaceTable">
                        <div class="header">
                            <div>#ID</div>
                            <div>Nome do Curso</div>
                            <div>Modificado em</div>
                        </div>
                        <div class="itens">
                            <draggable
                                :list="AllCourses"
                                :disabled="!enabled"
                                class="list-group"
                                ghost-class="ghost"
                                @end="onEnd"
                            >
                                <div v-for="item in AllCourses" :key="item.id" class="spaceItens" :data-id="item.id">
                                    <div>
                                        <div class="txt">{{ item.id }}</div>
                                    </div>
                                    <div>
                                        <div class="txt">{{ item.title }}</div>
                                    </div>
                                    <div>
                                        <div class="txt">{{ item.updated_at | moment("DD/MM/YYYY HH:mm") }}</div>
                                    </div>
                                </div>
                            </draggable>
                        </div>
                    </div>
                </div>
            </b-col>
        </b-row>
    </b-modal>
</template>
<script>
import CourseService from "@/services/resources/CourseService";
const serviceCourse = CourseService.build();
import draggable from 'vuedraggable'
import notify from "@/services/libs/notificacao";

export default {
    props:['AllHome'],
    components: {
        draggable
    },
    data(){
        return{
            selectedCategory:null,
            AllCourses:[],
            AllCategory:[],
            totalCursos:'',
            createNew:false,
            nameCategory:'',
            criar:true,
            id_edit:null,
            title_modal:'Nova Categoria',
            rowClass:'row-table-body',
            emptyText: "Sem Resultados",
            filterOn: [],
            fieldsx: [
                { key: 'title', label: 'Titulo Categoria', sortable: true},
                { key: 'updated_at', label: 'Modificado em', sortable: true},
                { key: 'id', label: 'Ações'},
            ],
            enabled: true,
            dragging: false,

        }
    },
    computed: {
        draggingInfo() {
            return this.dragging ? "under drag" : "";
        }
    },
    methods:{
        onEnd: function(e) {
            //console.log('event', e);
            setTimeout(() => {
                var array = []
                var lessons = document.querySelectorAll('.spaceItens');
                for (let i = 0; i < lessons.length; i++) {
                    const lessonId = lessons[i].getAttribute('data-id');
                    array.push(parseInt(lessonId));
                }
                //console.log('xxxxx', array);
                let data = {
                    id: '/order',
                    array
                };
                this.$root.$emit('loadOn');
                serviceCourse
                .postIDArray(data)
                .then(resp => {
                    //console.log("order course", resp, array);
                    notify('sucesso', 'Ordenação Salva!');
                    this.$root.$emit('loadOff');
                }).catch(err => {
                    //console.log(err);
                    notify('erro', 'Erro ao Salvar Ordenação!');
                    this.$root.$emit('loadOff');
                }); 
            }, 1000);
        },
        NewCategory(){
            //console.log(this.selectedCategory);
            this.AllCourses = this.selectedCategory.courses;
        },
    },
    mounted(){
        this.$root.$on('bv::show::modal', data => {
            if(data === "modal-ordenar-cuso"){
                this.selectedCategory = this.AllHome[0];
                this.NewCategory();
            }
		});
    }
}
</script>
<style lang="scss">

#modal-ordenar-cuso{
    .spaceTable{
        margin-top: 30px;
        .header {
            display: grid;
            grid-template-columns: 1fr 3fr 1fr;
            margin-bottom: 25px;
            div{
                font-family: Montserrat;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                letter-spacing: 0.2px;
                color: var(--fontcolor);
            }
        }
        .itens::-webkit-scrollbar {
            background-color: #fff;
            width: 2px;
        }
        .itens::-webkit-scrollbar-track {
            background-color: #c7c7c7;
        }
        .itens::-webkit-scrollbar-thumb {
            background-color: #4d4d4d52;
            border-radius: 31px;
        }
        .itens::-webkit-scrollbar-button {
            display:none;
        }
        .itens{
        height: 38vh;
        overflow: auto;
            .spaceItens{
                cursor: grab;
                display: grid;
                grid-template-columns: 1fr 3fr 1fr;
                height: 40px;
                border-bottom: 0.5px solid #ededf0;
                margin: 15px 5px;
                .txt{
                    font-family: Montserrat;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 13px;
                    letter-spacing: 0.2px;
                    color: #81858E; 
                }
                .actions{
                    display: flex;
                    .deletar{
                    font-family: Montserrat;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 13px;
                        letter-spacing: 0.2px;
                        color: #FF0C37;
                        cursor: pointer;
                    }
                    .editar{
                        font-family: Montserrat;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 13px;
                        letter-spacing: 0.2px;
                        color: #81858E;
                        margin-right: 15px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
    .createNewCategory{
        position: fixed;
        background: var(--fundomodal);
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 99999999999999999;
        .contentAdd{
            z-index: 9999999999999999999;
            position: absolute;
            padding: 30px;
            right: 0;
            top: 25vh;
            width: 303px;
            height: 295px;
            background: var(--backgroundcolor);
            border: 1px solid var(--bordercolor);
            box-sizing: border-box;
            border-radius: 3px;
            .close{
                cursor: pointer;
            }
            .space{
                margin-top: 40px;
                margin-bottom: 40px;
                input{
                    border: 1px solid var(--bordercolor);
                    box-sizing: border-box;
                    border-radius: 5px;
                    height: 40px;
                    font-family: Montserrat;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    color: #EDEDF0;
                    overflow:hidden !important;
                    resize:none !important;
                    background: white;
                }
                input:hover, input:focus{
                    border: 1px solid var(--maincolor);
                    box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
                    font-family: Montserrat;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    color: #000000;
                }
                label{
                    font-family: Montserrat;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 12px;
                    color: var(--fontcolor);
                }
                .btn-salvar{
                    background: var(--maincolor);
                    border-radius: 5px;
                    border: none;
                    width: 146px;
                    font-family: Montserrat;
                    color: #FFFFFF;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    height: 40px;
                }
            }
            .title{
                font-family: Montserrat;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                color: var(--maincolor);
            }
        }
    }
    .hide{
        display: none !important;
    }
    .table {
        margin-top: 40px;
        th {
            border: none !important;
            font-family: Montserrat !important;
            font-style: normal !important;
            font-weight: 600 !important;
            font-size: 14px !important;
            letter-spacing: 0.2px !important;
            color: #333 !important;
        }
        tr.row-table-body {
            line-height: 40px !important;
        }
        td {
            border-top: none !important;
            border-bottom: 1px solid #E5E5E5 !important;
        }
        .table thead th {
            border: none !important;
        }
        .text{
            font-family: Montserrat;
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            letter-spacing: 0.2px;
            color: #81858E;
        }
        .flex{
            display: flex;
            .deletar{
               font-family: Montserrat;
                font-style: normal;
                font-weight: 600;
                font-size: 13px;
                letter-spacing: 0.2px;
                color: #FF0C37;
                cursor: pointer;
            }
            .editar{
                font-family: Montserrat;
                font-style: normal;
                font-weight: 600;
                font-size: 13px;
                letter-spacing: 0.2px;
                color: #81858E;
                margin-right: 15px;
                cursor: pointer;
            }
        }
    }
    
    .totalCursos{
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        color: #81858E;
        margin-top: 30px;
    }
    .inputSearch {
        display: flex;
        margin-right: 30px;
        margin-top: 5px;
        input {
            width: 34em !important;
            height: 45px !important;
            box-sizing: border-box !important;
            border-radius: 0px !important;
            font-family: Montserrat !important;
            font-style: normal !important;
            font-weight: normal !important;
            font-size: 12px !important;
            border: none;
            color: #333 !important;
            padding-left: 45px !important;
            background: #fff !important;
            border-bottom: solid 1px var(--bordercolor) !important;
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03) !important;
        }
        svg{
            position: relative;
            left: 30px;
            top: 15px;
        }
    }
    .category{
        .lineDiv{
            height: 0.5px;
            background: #E5E5E5;
            transform: matrix(1, 0, 0, -1, 0, 0);
            width: 100%;
            margin-top: 40px;
            margin-bottom: 30px;
        }
    }
    .btn-NewCategoria{
        width: 240px;
        height: 45px;
        background: var(--maincolor);
        border-radius: 5px;
        border: none;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #FFFFFF;
    }
    .title{
        font-family: Montserrat;
        font-style: normal;
        font-weight: 800;
        font-size: 18px;
        letter-spacing: 0.2px;
        color: var(--fontcolor);
        margin-bottom: 5px;
    }
    .subtitle{
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        letter-spacing: 0.2px;
        color: #81858E;
    }
    .flexHeader{
        display: flex;
        justify-content: space-between;
        select{
            border: 1px solid var(--bordercolor);
            box-sizing: border-box;
            border-radius: 5px;
            height: 45px;
            font-family: Montserrat;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            color: #6d767e;
            overflow:hidden !important;
            resize:none !important;
        }
        select:hover, select:focus{
            border: 1px solid var(--maincolor);
            box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
            font-family: Montserrat;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            color: #000000;
        }
    }
    .modal-header{
        border:none !important
    }
}
</style>